// src/components/Leaderboard.js
import React from "react";

const leaderboardData = [
  { rank: 1, mobileNumber: "1234567890", score: 100 },
  { rank: 2, mobileNumber: "0987654321", score: 95 },
  { rank: 3, mobileNumber: "1122334455", score: 90 },
  // Add more leaderboard entries as needed
];

function Leaderboard() {
  return (
    <div className="main-content">
      <div className="container mt-4">
        <h2>Leaderboard</h2>
        <table className="table table-dark table-striped mt-3">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Mobile Number</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.rank}</td>
                <td>{entry.mobileNumber}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;
