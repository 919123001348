// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GameList from "./components/GameList";
import GameDetails from "./components/GameDetails";
import Navbar from "./components/Navbar";
import Leaderboard from "./components/Leaderboard"; // Import Leaderboard component
import ScrollToTopButton from "./components/ScrollToTopButton"; // Import ScrollToTopButton
import Footer from "./components/Footer"; // Import Footer
import "./App.css";

function App() {
  const [selectedGenre, setSelectedGenre] = useState("All");

  return (
    <Router>
      <div className="App">
        <header>
          {/* <h1>Giro Gamez</h1> */}
          <Navbar setSelectedGenre={setSelectedGenre} />
        </header>
        <main className="flex-grow-1">
          <Routes>
            <Route
              path="/"
              element={<GameList selectedGenre={selectedGenre} />}
            />
            <Route path="/game/:id" element={<GameDetails />} />
            <Route path="/leaderboard" element={<Leaderboard />} />{" "}
          </Routes>
        </main>
        <Footer /> {/* Include Footer */}
        <ScrollToTopButton /> {/* Add ScrollToTopButton */}
      </div>
    </Router>
  );
}

export default App;
