// src/components/LeaderboardGame.js
import React from "react";
import { Link } from "react-router-dom";
import gamesData from "../games.json";

function LeaderboardGame() {
  const leaderboardGame = gamesData.find((game) => game.id === 43);

  return (
    <div className="container mt-4">
      <h2 className="text-center text-light">
        <b>Leaderboard Game</b>
      </h2>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="card">
            <Link to={`/game/${leaderboardGame.id}`} className="game-name">
              <img
                src={leaderboardGame.game_cover_url}
                className="card-img-top"
                alt={leaderboardGame.game_name}
              />
              {/* <div className="card-body">
                <h5 className="card-title">{leaderboardGame.game_name}</h5>
              </div> */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardGame;
