// src/components/Footer.js
import React from "react";

function Footer() {
  return (
    <footer className="footer mt-5 py-3 bg-dark text-light">
      <div className="container text-center">
        <span>
          Giro Gamez - © 2024 All Rights Reserved. Powered by GiroFintech LTD
          DIFC, Dubai, UAE.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
